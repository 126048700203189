import React, {Fragment, useEffect, Suspense, lazy, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import Cookies from 'js-cookie'

// importing private routes
import PrivateRouteCheck from '../Utils/PrivateRouteCheck'
// importing components
import ScrollToTop from '../Utils/ScrollToTop'
import {useHistory} from 'react-router'
import setAuthToken from '../Utils/setAuthToken'
import InssaclubLoader from '../Components/InssaclubLoader/InssaclubLoader'

// css files
import '../Components/LoginRegister/LoginRegister.css'
import '../Components/HomeFirst/HomeFirst.css'
import '../Components/WelcomeScreen/WelcomeScreen.css'
import ContactUs from '../Pages/ContactUs'
import {checkApiStatus} from '../Api/AuthenticationApis'
import {BASE_URL} from '../Utils/GlobalVariable'
// import { setCurrencyCounty } from "../Store/Actions/Payment";

// implementing lazy loading

const Home = lazy(() => import('../Pages/Home'))
const NewHome = lazy(() => import('../Pages/NewHome/App'))
const Usertransactions = lazy(() =>
  import('../Components/InfluencerProfile/UserTransactions')
)
const CreatePage = lazy(() => import('../Pages/CreatePage'))
const Login = lazy(() => import('../Pages/Login'))
const PrivacyPolicy = lazy(() => import('../Pages/PrivacyPolicy'))
const TermsAndConditions = lazy(() => import('../Pages/TermsAndConditions'))
const ForgetPassword = lazy(() => import('../Pages/ForegetPassword'))
const UserInfluencerLogin = lazy(() =>
  import('../Components/UserInfluencerLogin/UserInfluencerLogin')
)
const InfluencerProfile = lazy(() => import('../Components/InfluencerProfile'))
const ImageOpenFullScreen = lazy(() =>
  import('../Components/ImageOpenFullScreen/ImageOpenFullScreen')
)
const CustomizedSnackbars = lazy(() =>
  import('../Components/CustomizedSnackbars/CustomizedSnackbars')
)
const DashboardRoutes = lazy(() => import('./DashboardRoutes'))

const UserRegister = lazy(() => import('../Pages/UserRegister'))
const PaymentDetails = lazy(() =>
  import('../Components/PaymentDetails/PaymentDetails')
)
const WelcomeScreen = lazy(() =>
  import('../Components/WelcomeScreen/WelcomeScreen')
)
const Register = lazy(() => import('../Pages/Register'))
const CreatePassword = lazy(() => import('../Pages/CreatePassword'))
const CreateAgency = lazy(() => import('../Pages/CreateAgency'))
const VideoCall = lazy(() => import('../Pages/VideoCall'))
const LoaderPage = lazy(() => import('../Pages/Loader'))

const Routes = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const history = useHistory()

  const handleCheckApiStatus = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/status`, {
        credentials: 'include',
      })
      console.log({response})
    } catch (error) {
      console.log(error)
    }
  }, [])

  const checkCookiesAndFetchStatus = useCallback(() => {
    console.log({Cookies}, 'cookies..')
    const keyPairId = Cookies.get('CloudFront-Key-Pair-Id')
    const policy = Cookies.get('CloudFront-Policy')
    const signature = Cookies.get('CloudFront-Signature')

    if (!keyPairId && !policy && !signature) {
      console.log(
        'API status calling...',
        'keyPairId && policy && signature',
        keyPairId && policy && signature
      )
      handleCheckApiStatus()
      return
    }
  }, [handleCheckApiStatus])

  useEffect(() => {
    checkCookiesAndFetchStatus()
  }, [checkCookiesAndFetchStatus])

  useEffect(() => {
    if (localStorage.getItem('auth-token')) {
      setAuthToken()
    } else {
      if (state.userReducer.userType === 'influencer') {
        localStorage.clear()
        history.push('/p/signin')
      }
    }

    const getlocalStorageData = () => {
      let localStorageData = JSON.parse(localStorage.getItem('influencer'))
      if (localStorageData) {
        dispatch({
          type: 'LOCALSTORAGE_STATE_UPDATE',
          payload: {
            ...localStorageData,
          },
        })
      }
    }
    getlocalStorageData()
  }, [dispatch, history, state.userReducer.userType])

  // ------------------------------------

  return (
    <Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <InssaclubLoader />
            </div>
          }
        >
          <ImageOpenFullScreen />
          <CustomizedSnackbars />
          <Switch>
            {/* <Route path="/" exact component={Home} /> */}
            <Route
              path='/'
              exact
              component={NewHome}
            />
            <Route path='/p/create' exact component={CreatePage} />
            <Route path='/p/signin' exact component={Login} />
            <Route path='/p/privacy' exact component={PrivacyPolicy} />
            <Route path='/p/contact-us' exact component={ContactUs} />
            <Route path='/p/terms' exact component={TermsAndConditions} />
            <Route path='/invite/:referralKey' exact component={CreatePage} />
            <Route exact path='/p/forgot-password' component={ForgetPassword} />
            <Route path='/p/signup' exact component={UserInfluencerLogin} />
            <Route path='/p/user-registration' exact component={UserRegister} />
            <Route path='/:username' exact component={InfluencerProfile} />
            <Route
              path='/:username/share/:postType/:postId'
              exact
              component={InfluencerProfile}
            />
            <PrivateRouteCheck
              type={'USER_DEFAULT_PAYMENT'}
              path='/p/payments'
              exact
              component={PaymentDetails}
            />
            <PrivateRouteCheck
              type={'USER_DEFAULT_PAYMENT'}
              path='/p/user/transactions'
              exact
              component={Usertransactions}
            />
            <PrivateRouteCheck
              type={'INFLUENCER_REGISTER_WELCOME'}
              path='/p/welcome'
              exact
              component={WelcomeScreen}
            />
            <PrivateRouteCheck
              type={'REGISTER_COMPONENT'}
              path='/p/creator-registration'
              exact
              component={Register}
            />
            <Route
              path="/meet/:meetingId/client/:clientId"
              exact
              component={VideoCall}
            />
            <Route
              path="/meet/loading"
              exact
              component={LoaderPage}
            />

            <Route
              path='/p/:username/reset-password/:token'
              exact
              component={CreatePassword}
            />
            <Route path='/p/create-agency' exact component={CreateAgency} />
            <DashboardRoutes />
            <Redirect to='/' />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  )
}

export default Routes
